import React, { useEffect, useState } from "react";
import jwt from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

// import Menu_Laterale from '../components/menu_laterale'
import SelectionSquadreAtleti from './components/selectionSqAt'
import Anagrafica from './components/anagrafica_atleta'
import Performance from './components/performance'
import CalcolaPTSD from './components/calcolaPtsd'
import ParametriPTSD from './components/parametriPtsd'
import Ancore from './components/ancora'
import Sidebar from "./components/sidebar";

function Statistiche() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [selectedAtleta, setSelectedAtleta] = useState(-1)
  const [idCombinazione, setIdCombinazione] = useState(-2)
  const [idBestCombinazione, setIdBestCombinazione] = useState(-2)
  const [showDeleted, setShowDeleted] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = token ? jwt(token) : null;

    if (!user) {
      localStorage.removeItem('token');
      navigate('/login');
    }
  })

  return (
    <div className="row  " >
      <Sidebar />
      <div id="pagina" className="col-xs col-sm bgRight pad-T2 pad-L2 pad-R2 row-height row-height-safari ">
        <h1> {t('statistiche')} </h1>
        <Ancore />

        <div className="brdBot">
          <div className="row">
            <SelectionSquadreAtleti setSelectedAtleta={setSelectedAtleta} selectedAtleta={selectedAtleta} setShowDeleted={setShowDeleted} />
            { selectedAtleta != -1 && <Anagrafica selectedAtleta={selectedAtleta} />}
          </div>
        </div>

        <div className="divider div-transparent div-arrow-down"></div>
        { selectedAtleta != -1 && (
          <>
            <Performance idAtleta={selectedAtleta} />
            <div className="divider div-transparent div-arrow-down"></div>
            <div>
              <CalcolaPTSD idAtleta={selectedAtleta} setIdCombinazione={setIdCombinazione} idBestCombinazione={idBestCombinazione} />
              {/* <ParametriPTSD idAtleta={selectedAtleta} idCombinazione={idCombinazione} setIdBestCombinazione={setIdBestCombinazione} /> */}
            </div>
          </>
        )}

      </div>
    </div>
  )
}

export default Statistiche