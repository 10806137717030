import React, { useState, useEffect } from 'react';
import { VictoryChart, VictoryAxis, VictoryArea, VictoryBar } from 'victory';
import { useTranslation } from "react-i18next";
import ParametriPTSD from './parametriPtsd'
import { API_URL } from '../config';

export default function CalcolaPTSD({ idAtleta, setIdCombinazione }) {
    const { t } = useTranslation();

    const [idSchema, setIdSchema] = useState(-1);
    const [idSequence, setIdSequence] = useState(-1);

    const [sequence, setSequence] = useState([]);
    const [schemi, setSchemi] = useState([]);
    const [tempo, setTempo] = useState([]);
    const [TMEcombinazioni, setTMECombinazioni] = useState([]);
    const [tempoSchemaUtente, setTempoSchemaUtente] = useState([]);
    const [GraficoSequenza, setGraficoSequenza] = useState([]);
    const [idCombinazione] = useState(-2);
    const [setIdBestCombinazione] = useState(-2);
    const dynamicHeight = Math.min(400, 200 + tempoSchemaUtente * 10);
    const maxY = tempo > tempoSchemaUtente ? tempo + 5 : tempoSchemaUtente + 5;
    const step = Math.ceil(maxY / 5); // Numero di tick regolabile
    const tickValues = Array.from({ length: 6 }, (_, i) => i * step);
    // for (let i = 0; i <= tempoSchemaUtente + 5; i += 2) {
    //     tickValues.push(i);
    // }
    const callAPIallSequence = async () => {
        try {
            const customerId = localStorage.getItem('customerId');
            const response = await fetch(`${API_URL}/api/sequence`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    customerId: customerId,
                    userId: idAtleta
                }),
            })

            const data = await response.json();

            // Ordina l'array in base al valore della chiave "name"
            data.schemiSequences.sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            });

            data.schemiSequences.sort((a, b) => {
                if (a.schemaName < b.schemaName) return -1;
                if (a.schemaName > b.schemaName) return 1;
                return 0;
              });
            setSequence(
                data.schemiSequences.filter(
                  (item, index, self) =>
                    index === self.findIndex((t) => t.sequenceId === item.sequenceId)
                )
              );
            //setSchemi(data.schemiSequences);
        }
        catch (err) {
            console.log(err);
        }
    };

    const changeSequence = async (idSequenza) => {
        try {
            const customerId = localStorage.getItem('customerId');
            const response = await fetch(`${API_URL}/api/tempoSequenza`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    idAtleta: idAtleta,
                    customerId: customerId
                }),
            })

            const data = await response.json();
            setIdSequence(idSequenza);
            //setCombinazione(0);
            setTempo(data.velocita);
            callAPIschemi(idSequenza);
        }
        catch (err) {
            console.log(err);
        }
    }

    const callAPIschemi = async (idSequenza) => {
      try {
        const customerId = localStorage.getItem('customerId');
        const response = await fetch(`${API_URL}/api/schemi`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idSequenza: idSequenza,
            idAtleta: idAtleta,
            customerId: customerId
          }),
        });

        const data = await response.json();
        //console.log("SCHEMI: "+JSON.stringify(data));
        // Ordina l'array in base al valore della chiave "name"
        data.schemi.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });

        setSchemi(data.schemi);
        //setIdSchemi(data.idSchemi);
        //scallAPIStoricoCombinazione(idSchema);

      } catch (err) {
        console.log(err);
      }
    };

    function setCombinazione(idSchema) {
        setIdSchema(idSchema);
        setIdCombinazione(idSchema);
        callAPITMECombinazioni(idSchema);
        callAPIStoricoCombinazione(idSchema);
    }

    const callAPIStoricoCombinazione = async (idSchema) => {
        try {
            const customerId = localStorage.getItem('customerId');
            const response = await fetch(`${API_URL}/api/storicoCombinazione`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    idSchema: idSchema,
                    idAtleta: idAtleta,
                    customerId: customerId
                }),
            })

            const data = await response.json(); 
            let graficoSequenza = []
            for(let d of data.storico){
                graficoSequenza.push({
                    //Aggiungo un offset in millisecondi (l'id del dato) per evitare che siano duplicati nel grafico
                    x: d.data,
                    y: d.executionTime,
                    _id: d._id
                })
            }
            setGraficoSequenza(graficoSequenza);
        }
        catch (err) {
            console.log(err);
        }
    };

    const callAPITMECombinazioni = async (idSchema) => {
        try {
            const customerId = localStorage.getItem('customerId');
            const response = await fetch(`${API_URL}/api/tmeSchema`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    idSchema: idSchema,
                    idAtleta: idAtleta,
                    customerId: customerId
                }),
            })

            const data = await response.json(); 
            setTMECombinazioni(data.tme);
            setTempoSchemaUtente(data.minMaxFirstTime);
        }
        catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        callAPIallSequence();
        changeSequence(0);
    }, [idAtleta]);



    return (
        <div className="mrgT2">
            <div className="row ">
                <div className="col-xs-12 col-sm-12 pad-L0">
                    <h2>{t('calcola_ptsd')}</h2>
                    <span>
                        <h5 className="long">{t('velocita_30m')}</h5>
                        <span className="dato2">{tempo}'' </span>
                    </span>
                    <br />
                    <br />
                    <label className='width-label-10' >{t('sequenza')}</label>
                    <select name="sequenza" id="sequenza" className="small" value={idSequence} onChange={(event) => changeSequence(event.target.value)}>
                        <option value="0" key="0">{t('nessuna')}</option>
                        {

                            sequence.map((elemento, id) => {
                                return (
                                    <option value={elemento.sequenceId} key={id}>{elemento.name}</option>
                                );
                            })

                        }
                    </select>

                    <div className='row align-items-baseline'>
                        <label className='width-label-10' >{t('combinazione')}</label>
                        <select name="combinazione" id="combinazione" className="small" value={idSchema} onChange={(event) => setCombinazione(event.target.value)}>
                            <option value="0" key="0">{t('nessuna')}</option>
                            {

                                schemi.map((elemento, id) => {
                                    return (
                                        <option value={elemento.id} key={elemento.id}>{elemento.name}</option>
                                    );
                                })

                            }
                        </select>

                        <label className='mx-3'>{t('combinazioni_nascoste')}</label>
                    </div>

                    { <div > {/*className="contGrafico"*/}
                        <VictoryChart domain={{ y: [0, tempo > tempoSchemaUtente ? tempo + 5 : tempoSchemaUtente +5 ] }} style={{ background: { fill: "#555555" } }} width={1000} height={dynamicHeight}>
                            <linearGradient id="myGradient2" gradientTransform="rotate(90)">
                                <stop offset="10%" stopColor="#b83e19" />
                                <stop offset="100%" stopColor="#0e1e37" />
                            </linearGradient>
                            <VictoryAxis tickValues={[1, 2, 3, 4]} style={{ tickLabels: { fill: "none" } }} />
                            <VictoryAxis dependentAxis width={400} height={400} tickValues={tickValues} domain={{ y: [0, tempo > tempoSchemaUtente ? tempo + 5 : tempoSchemaUtente +5] }} standalone={false} />
                            <VictoryBar interpolation="natural"
                                style={{
                                    data: { fill: "url(#myGradient2)" }
                                }}

                                data={[
                                    { x: 2, y: tempo },
                                    { x: 3, y: tempoSchemaUtente }
                                ]}
                            />
                        </VictoryChart>
                        <h4 style={{ marginTop: '1em' }}>{t('descr_grafico_velocita_combinazione')}</h4>
                    </div> }
                    <div className="divider div-transparent div-arrow-down"></div>
                    <h2 style={{ marginTop: '1em' }}>{t('detailAnalysis')}</h2>
                    <ParametriPTSD idAtleta={idAtleta} idCombinazione={idSchema} setIdBestCombinazione={setIdBestCombinazione} />
                    {/* <div > */}

                        {/* Grafico che prende il tempo di esecuzione dalle statistiche di ogni combinazione effettuata, ordinata per schemaId sulle x,
                         sulle y il tempo di esecuzione */}
                        {/* <VictoryChart style={{ background: { fill: "#555555" } }} domain={{ y: [0, 50] }} width={1000} height={200}>
                        <VictoryAxis dependentAxis domain={[0, 50]} standalone={false} />
                        <VictoryAxis style={{
                            tickLabels: { fill: "white", fontSize: 12, fontWeight: "bold" }}}
                            />
                        

                        <linearGradient id="myGradient" gradientTransform="rotate(90)">
                            <stop offset="10%" stopColor="#b83e19" />
                            <stop offset="100%" stopColor="#0e1e37" />
                        </linearGradient>

                        <VictoryArea
                            interpolation="natural"
                            style={{ data: { fill: "url(#myGradient)" } }}
                            data={GraficoSequenza}
                            key={({data}) => data._id}
                        />
                        </VictoryChart>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
