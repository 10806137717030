import React from "react";
import '../styles/popup.css'; // Per lo stile del popup

const popup = ({ message, onClose }) => {
    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <p>{message}</p>
                <button onClick={onClose} className="popup-button">OK</button>
            </div>
        </div>
    );
};

export default popup;