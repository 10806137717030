import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import Formazione from './Formazione';
import Documentazione from './Documentazione';
import Statistiche from "./Statistiche";
import { CustomerProvider } from './context/CustomerContext'; // Importa il provider del contesto

function App() {
  return (
    <CustomerProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/dashboard" exact element={<Dashboard />} />
          <Route path="/formazione" exact element={<Formazione />} />
          <Route path="/documentazione" exact element={<Documentazione />} />
          <Route path="/statistiche" exact element={<Statistiche />} />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </BrowserRouter>
    </CustomerProvider>
  );
}

export default App;
