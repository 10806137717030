import React, { createContext, useState, useEffect, useContext } from "react";

export const CustomerContext = createContext();

export const CustomerProvider = ({ children }) => {
    const [customerData, setCustomerData] = useState(() => {
        // Carica i dati dal localStorage al caricamento iniziale
        const savedCustomerData = localStorage.getItem("customerData");
        return savedCustomerData ? JSON.parse(savedCustomerData) : null;
    });

    // Effetto per salvare automaticamente i dati nel localStorage quando cambiano
    useEffect(() => {
        if (customerData) {
            localStorage.setItem("customerData", JSON.stringify(customerData));
        } else {
            localStorage.removeItem("customerData");
        }
    }, [customerData]);

    return (
        <CustomerContext.Provider value={{ customerData, setCustomerData }}>
            {children}
        </CustomerContext.Provider>
    );
};

export const useCustomer = () => {
    const context = useContext(CustomerContext);
    if (!context) {
        throw new Error("useCustomer must be used within a CustomerProvider");
    }
    return context;
};